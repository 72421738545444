import MailOutlineIcon from '@mui/icons-material/MailOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { IconButton, Input, InputAdornment, InputLabel } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { GetServerSideProps } from 'next';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { capitalizeString } from '../../components/utils';
import { loginReqIdAction, resolvedTokenAction } from '../../redux/actions/securityActionCreator';
import getRequestIdGenerator from '../../services/requestIdGenerator';
import { getLocale } from '../../services/utils/get-translates';
import axios from 'axios';

const RootGrid = styled(Grid)({
    paddingLeft: 15,
    paddingRight: 15,
});

const Image360 = styled('img')({
    height: 17,
});

const LoginTypography = styled(Typography)({
    fontSize: 30,
    textTransform: 'uppercase',
    fontWeight: 900,
    lineHeight: 1.1,
    marginBottom: 15,
});

const CustomBackdrop = styled(Backdrop)({
    zIndex: 300,
});

const CustomIcon = styled(MailOutlineIcon)({
    marginRight: 7,
});

const ButtonsGrid = styled(Grid)({
    marginTop: 5,
});

interface Props {}

const Login = ({}: Props) => {
    const tCommon = useTranslations('common');
    const dispatch = useDispatch();
    const router = useRouter();
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [showPassword, setShowPassword] = useState<any>(false);
    const { enqueueSnackbar } = useSnackbar();
    const [loadingState, setLoadingState] = useState(false);

    const handleChangeUsername = ({ target: { value } }: any) => {
        setUsername(value);
    };

    const handleChangePassword = ({ target: { value } }: any) => {
        setPassword(value);
    };

    const handleLogin = () => {
        setLoadingState(true);
        dispatch(
            loginReqIdAction.fn(getRequestIdGenerator().gen(), username, password, {
                // @ts-ignore
                onSuccess: ({ token, refresh_token }: any) => {
                    axios
                        .get('/api/token', {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        })
                        .then(() => {
                            setTimeout(() => {
                                if ('root-address' in router.query) {
                                    //@ts-ignore
                                    window.parent.postMessage('success-auth', router.query['root-address']);
                                }
                            }, 100);
                        });
                    dispatch(resolvedTokenAction.fn(token, refresh_token));

                    setLoadingState(false);
                },
                onFailure: (error) => {
                    console.error(error);
                    enqueueSnackbar(tCommon('errors.noAccessWrongCompany'), {
                        variant: 'error',
                    });
                    setLoadingState(false);
                },
            })
        );
    };

    const handleReturn = () => {
        if ('root-address' in router.query) {
            //@ts-ignore
            window.parent.postMessage('cancel-auth', router.query['root-address']);
        }
    };

    const handleClickShowPassword = () => {
        setShowPassword((previousValue: boolean) => setShowPassword(!previousValue));
    };

    return (
        <Fragment>
            <RootGrid container direction={'column'} spacing={2} alignItems={'stretch'}>
                <Grid item>
                    <Grid container direction={'column'} alignItems={'start'} style={{ marginTop: 20 }}>
                        <Grid item>
                            <Image360 src={'/images/logo360sc-horizontal.png'} />
                        </Grid>
                        <Grid item>
                            <LoginTypography>{String(tCommon('texts.login'))}</LoginTypography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item>
                    <FormControl variant='standard' fullWidth>
                        <InputLabel htmlFor='standard-adornment-user'>
                            {capitalizeString(String(tCommon('texts.usernameFieldPlaceholder')))}
                        </InputLabel>
                        <Input
                            id='standard-adornment-user'
                            type={'text'}
                            value={username}
                            onChange={handleChangeUsername}
                            endAdornment={
                                <InputAdornment position='end'>
                                    <CustomIcon />
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Grid>

                <Grid item>
                    <FormControl variant='standard' fullWidth>
                        <InputLabel htmlFor='standard-adornment-password'>
                            {capitalizeString(String(tCommon('texts.passwordFieldPlaceholder')))}
                        </InputLabel>
                        <Input
                            id='standard-adornment-password'
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            onChange={handleChangePassword}
                            endAdornment={
                                <InputAdornment position='end'>
                                    <IconButton onClick={handleClickShowPassword}>
                                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Grid>

                <Grid item>
                    <ButtonsGrid container direction={'column'} spacing={1}>
                        <Grid item>
                            <Button data-testid='login' variant={'contained'} color={'primary'} onClick={handleLogin}>
                                {tCommon('buttons.connection')}
                            </Button>
                        </Grid>

                        <Grid item>
                            <Button data-testid='login' variant={'contained'} color={'primary'} disabled>
                                {tCommon('buttons.recoverEmail')}
                            </Button>
                        </Grid>

                        <Grid item>
                            <Button data-testid='return' color={'primary'} onClick={handleReturn}>
                                {tCommon('buttons.return')}
                            </Button>
                        </Grid>
                    </ButtonsGrid>
                </Grid>
            </RootGrid>

            <CustomBackdrop open={loadingState}>
                <CircularProgress />
            </CustomBackdrop>
        </Fragment>
    );
};

export default Login;

export const getServerSideProps: GetServerSideProps = async (context) => {
    return {
        props: {
            messages: {
                ...require(`../../messages/common/${getLocale(context)}.json`),
            },
        },
    };
};
