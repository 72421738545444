import { Icon } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { AIR_TABLE_TOKEN_IDENTIFIER } from '../services/utils/CONST';
import { Avatar } from '../services/utils/types';

export const capitalizeString = (value: string) => value.charAt(0).toUpperCase() + value.slice(1);

const convertIconName = (iconName: string) => {
    let data = iconName.match(/[A-Z][a-z]+/g);
    let newData = '';
    data &&
        data.map((item) => {
            newData = `${newData}${item.toLowerCase()}_`;
        });
    return newData.slice(0, newData.length - 1);
};

export const extractIconAndName = (iconId: string) => {
    const temp = iconId.split('/');
    return temp[2].split(';');
};

export const resolveIcon = (iconId: string | null, defaultIcon: any, props?: any) => {
    const materialIconRegularExpression = /@material-ui\/icons\/.+/;
    if (iconId && materialIconRegularExpression.test(iconId)) {
        const dataName = extractIconAndName(iconId);
        const iconName = dataName[0];
        const color = dataName.length > 1 ? dataName[1] : null;
        return React.createElement(
            Icon,
            {
                ...(props ? props : {}),
            },
            convertIconName(iconName)
        );
    } else {
        return React.createElement(Icon, { ...(props ? props : {}) }, convertIconName(defaultIcon));
    }
};

export const renderDangerousText = (text: string, props: any = {}, el: string = 'span') => {
    const styles = {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        ...(props.hasOwnProperty('style') ? props['style'] : {}),
    };
    const mStyles = props.hasOwnProperty('style') && props['style'] ? props['style'] : styles;
    return React.createElement(el, {
        ...props,
        style: {
            ...mStyles,
        },
        dangerouslySetInnerHTML: { __html: text },
    });
};

// export const toFormatDate = (stringDate: string, momentFormat = 'DD/MM/YYYY  hh:mm:ss') => {
export const toFormatDate = (stringDate: string, momentFormat = 'DD/MM/YYYY  HH:mm:ss') => {
    const date = moment(stringDate);
    return date.format(momentFormat);
};

export const getPathProcess = (startNode: any) => {
    let nodesTemp = [];
    let result = [];
    let visited: any = {};
    let resultVisited: any = {};

    if (
        (startNode.hasOwnProperty('isStartNodeBridgeToStartProcess') &&
            startNode.isStartNodeBridgeToStartProcess === true) ||
        (startNode.hasOwnProperty('name') && startNode.name.length === 0)
    ) {
        startNode =
            Array.isArray(startNode.nextNodes) && startNode.nextNodes.length > 0 ? startNode.nextNodes[0] : null;
    }

    if (startNode && startNode.hasOwnProperty('nextNodes')) {
        result.push(startNode);
        nodesTemp.push(startNode);
    }

    while (nodesTemp.length > 0) {
        let node: any = nodesTemp.shift();

        if (node.id in visited) {
            continue;
        }
        visited[node.id] = true;
        resultVisited[node.id] = true;

        let nextNodes = node.nextNodes && node.nextNodes.length > 0 ? node.nextNodes : null;

        if (nextNodes) {
            nextNodes.forEach((nextN: any) => {
                nodesTemp.push(nextN);
                if (!(nextN.id in resultVisited)) {
                    resultVisited[nextN.id] = true;
                    result.push(nextN);
                }
            });
        }
    }

    return result;
};

export const dataURLtoFile = (dataUrl: any, filename: any) => {
    let arr = dataUrl.split(',');
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
};

export const getName = (nameWithPrefix: string) => {
    if (nameWithPrefix) return nameWithPrefix.split('-prefix-').reverse()[0];
    return nameWithPrefix;
};

export const getAirTableToken = (avatar: Avatar | null) => {
    if (!avatar) return '';

    // @ts-ignore
    const identifiers = avatar?.company?.identifiers;
    let token = '';
    if (identifiers) {
        identifiers.map((id: string) => {
            if (id.includes(AIR_TABLE_TOKEN_IDENTIFIER)) {
                token = id.split(':')[1];
            }
        });
    }
    return token;
};

export const generateRandomId = (): string => Math.random().toString(36).substr(2, 9);

export const hasRole = (rolesToCheck: string, roles: string[]) => roles.includes(rolesToCheck);
